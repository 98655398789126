import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import DeviceTableRow from "../components/DeviceTableRow";
import { actionGetAllFaxDevices } from "../store/actions/faxDeviceAction";

function DeviceList({ loader, faxDevicesData, actionGetAllFaxDevices }) {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // Fetch devices
    actionGetAllFaxDevices();
  }, [actionGetAllFaxDevices]);

  useEffect(() => {
    if (faxDevicesData?.result?.data?.faxDevices?.data) {
      const devices = faxDevicesData.map((device) => ({
        id: device.device_ID,
        deviceNumber: device.device_number || "N/A",
        activationDate: device.activation_date,
        billingDate: device.billing_date,
        status: device.status,
        actions: "Edit/Delete",
      }));
      setFilteredRecords(devices);
    }
  }, [faxDevicesData]);

  useEffect(() => {
    if (faxDevicesData) {
      const devices = faxDevicesData.filter((device) =>
        device.device_ID.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredRecords(devices);
    }
  }, [searchText, faxDevicesData]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Device List</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card invoiceCard">
                <div className="card-header">
                  <h4 className="card-title">Device List</h4>
                </div>
                <div className="card-body">
                  <div className="dateSearch mb-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search by Device ID"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Device ID</th>
                          <th>Device Name</th>
                          <th>Activation Date</th>
                          <th>Billing Date</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr>
                            <td colSpan="6" className="text-center">
                              Loading fax devices...
                            </td>
                          </tr>
                        ) : filteredRecords.length > 0 ? (
                          filteredRecords.map((record, index) => (
                            <DeviceTableRow
                              faxDeviceID={record.fax_device_ID}
                              key={index}
                              index={index}
                              device_ID={record.device_ID}
                              deviceName={record.device_name}
                              activationDate={record.activation_date}
                              billingDate={record.billing_date}
                              isActive={record.status === "ACTIVE"}
                            />
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No records found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loader: state.faxDevice.loader,
  faxDevicesData: state.faxDevice.faxDevicesData,
});

export default connect(mapStateToProps, {
  actionGetAllFaxDevices,
})(DeviceList);
