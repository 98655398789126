import { combineReducers } from "redux";
import authReducer from "./authReducer";
import inboxReducer from "./inboxReducer";
import settingReducer from "./settingReducer";
import sentFaxesReducer from "./sentFaxesReducer";
import referralReducer from "./referralReducer";
import paymentReducer from "./paymentReducer";
import faxDeviceReducer from "./faxDeviceReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  inbox: inboxReducer,
  setting: settingReducer,
  sentFax: sentFaxesReducer,
  referral: referralReducer,
  payment: paymentReducer,
  faxDevice: faxDeviceReducer,
});

export default rootReducer;
