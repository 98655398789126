import * as actionTypes from "../action";

const initialState = {
  loading: false,
  transactions: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  monthlyTotals: [],
  monthlyTotalLoader: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload.transactions,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case actionTypes.FETCH_PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.FETCH_MONTHLY_TOTALS_REQUEST:
      return {
        ...state,
        monthlyTotalLoader: true,
      };

    case actionTypes.FETCH_MONTHLY_TOTALS_SUCCESS:
      return {
        ...state,
        monthlyTotalLoader: false,
        monthlyTotals: action.payload,
      };

    default:
      return state;
  }
};
export default authReducer;
