import axios from "axios";
import * as actionTypes from "../action";
import { setAuthToken } from "../../utils/helper";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
const TWILLIO_BASE_URL = process.env.REACT_APP_TWILLIO_BASE_URL;
const VERIFIED_NUMBER_EMAIL_BASE_URL =
  process.env.REACT_APP_VERIFIED_NUMBER_EMAIL_BASE_URL;

///monthly-totals

export const fetchPaymentHistory =
  (page = 1, limit = 10) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_PAYMENT_HISTORY_REQUEST });

    try {
      // Make API call with pagination params
      const response = await axios.get(`${BASE_URL}/payment/payment-history`, {
        params: {
          page, // Current page
          limit, // Records per page
        },
      });

      const { data } = response.data.result;

      dispatch({
        type: actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
        payload: {
          transactions: data.transactions,
          currentPage: data.currentPage,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: actionTypes.FETCH_PAYMENT_HISTORY_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchMonthlyTotals = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_MONTHLY_TOTALS_REQUEST });

  try {
    const response = await axios.get(`${BASE_URL}/payment/monthly-totals`);

    const { data } = response.data.result;

    dispatch({
      type: actionTypes.FETCH_MONTHLY_TOTALS_SUCCESS,
      payload: data, // Assuming the response data structure aligns with your requirement
    });
  } catch (error) {
    console.log({ error });
  }
};
