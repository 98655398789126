import axios from "axios";
import * as actionTypes from "../action";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
const TWILLIO_BASE_URL = process.env.REACT_APP_TWILLIO_BASE_URL;
const VERIFIED_NUMBER_EMAIL_BASE_URL =
  process.env.REACT_APP_VERIFIED_NUMBER_EMAIL_BASE_URL;

export const actionAddReferral =
  (request, navigate) => async (dispatch, getState) => {
    console.log("actionAddReferral");
    dispatch({ type: actionTypes.ADD_REFERRAL_LOADER, payload: true });

    try {
      const res = await axios.post(`${BASE_URL}/referrals/add`, request);

      dispatch({ type: actionTypes.ADD_REFERRAL_LOADER, payload: false });

      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        message.success("Referral Added Successfully");
        navigate && navigate("/referral");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message || "Something went wrong");
      dispatch({ type: actionTypes.ADD_REFERRAL_LOADER, payload: false });
    }
  };

export const actionGetReferrals = () => async (dispatch, getState) => {
  console.log("actionGetReferrals");
  dispatch({ type: actionTypes.GET_REFERRALS_LOADER, payload: true });

  try {
    const res = await axios.get(`${BASE_URL}/referrals`);

    dispatch({ type: actionTypes.GET_REFERRALS_LOADER, payload: false });

    console.log({ res });
    if (res.status === 200) {
      dispatch({
        type: actionTypes.GET_REFERRALS_DATA,
        payload: res.data.result.data,
      });
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error?.response?.data?.message || "Something went wrong");
    dispatch({ type: actionTypes.GET_REFERRALS_LOADER, payload: false });
  }
};

export const actionResendReferral = (referralId) => async (dispatch) => {
  console.log("actionResendReferral");
  dispatch({ type: actionTypes.RESEND_REFERRAL_LOADER, payload: referralId });

  try {
    const res = await axios.get(`${BASE_URL}/referrals/resend/${referralId}`);

    dispatch({ type: actionTypes.RESEND_REFERRAL_LOADER, payload: false });

    if (res.status === 200) {
      message.success("Referral invitation resent successfully");
    } else {
      console.log(res.data.error?.message || "Failed to resend referral");
      message.error(res.data.error?.message || "Failed to resend referral");
    }
  } catch (error) {
    console.error(error?.response?.data?.message || "Something went wrong");
    dispatch({ type: actionTypes.RESEND_REFERRAL_LOADER, payload: false });
  }
};
