import * as actionTypes from "../action";

const initialState = {
  addFaxDeviceLoader: false,
  deleteFaxDeviceLoader: false,
  loader: false,
  faxDevicesData: [],
  faxDevice: {},
};

const faxDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FAX_DEVICE_LOADER:
      return { ...state, addFaxDeviceLoader: action.payload };

    case actionTypes.GET_ALL_FAX_DEVICES_LOADER:
      return { ...state, loader: action.payload };

    case actionTypes.GET_ALL_FAX_DEVICES_DATA:
      return { ...state, faxDevicesData: action.payload };

    case actionTypes.GET_SINGLE_FAX_DEVICE_DATA:
      return { ...state, faxDevice: action.payload };

    case actionTypes.DELETE_FAX_DEVICE_LOADER:
      return { ...state, deleteFaxDeviceLoader: action.payload };

    case actionTypes.DELETE_FAX_DEVICE_SUCCESS:
      return {
        ...state,
        faxDevicesData: state.faxDevicesData.filter(
          (d) => parseInt(d.fax_device_ID) != parseInt(action.payload)
        ),
      };

    default:
      return state;
  }
};
export default faxDeviceReducer;
