import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function AddFundForm() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    amount: "",
    email: "",
    cardHolderName: "",
    country: "US", // Default country
    zip: "",
  });
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { amount, email, cardHolderName, country, zip } = formData;

    // Validate inputs
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError("Please enter a valid amount.");
      return;
    }
    if (!email || !cardHolderName || !country || !zip) {
      setError("Please fill in all the required fields.");
      return;
    }

    setIsProcessing(true);
    setError("");

    try {
      // Create payment intent on the server
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payment/create-payment-intent`,
        {
          amount: amount * 100,
          email,
          cardHolderName,
          country,
          zip,
        }
      );

      const clientSecret = response?.data?.result?.data?.clientSecret;

      if (!clientSecret) {
        throw new Error("Failed to retrieve client secret from backend");
      }

      const cardElement = elements.getElement(CardElement);

      // Confirm card payment with additional details
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardHolderName,
              email: email,
              address: {
                country: country,
                postal_code: zip,
              },
            },
          },
        }
      );

      if (error) {
        setError(error.message);
      } else if (paymentIntent.status === "succeeded") {
        Swal.fire({
          icon: "success",
          title: "Payment Successful!",
          text: "Amount has been added to your Foipin Wallet.",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/billing/history");
          }
        });
      } else {
        setError("Payment was not successful. Please try again.");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred. Please try again.");
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div className="form-group">
        <label htmlFor="amount">Amount (USD):</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          placeholder="Enter amount"
          min="0.01"
          step="0.01"
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
        />
      </div>
      <div className="form-group">
        <label htmlFor="cardHolderName">Cardholder Name:</label>
        <input
          type="text"
          id="cardHolderName"
          name="cardHolderName"
          value={formData.cardHolderName}
          onChange={handleChange}
          placeholder="Enter cardholder's name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">Country:</label>
        <select
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
        >
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="GB">United Kingdom</option>
          <option value="AU">Australia</option>
          <option value="IN">India</option>
          <option value="DE">Germany</option>
          <option value="FR">France</option>
          <option value="JP">Japan</option>
          <option value="CN">China</option>
          <option value="BR">Brazil</option>
          <option value="ZA">South Africa</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="zip">ZIP Code:</label>
        <input
          type="text"
          id="zip"
          name="zip"
          value={formData.zip}
          onChange={handleChange}
          placeholder="Enter ZIP code"
        />
      </div>
      <div className="card-element">
        <CardElement
          options={{
            hidePostalCode: true,
          }}
        />
      </div>
      <button
        type="submit"
        className="submit-button"
        disabled={!stripe || isProcessing || !formData.amount}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
      {error && <div className="error-message">{error}</div>}
    </form>
  );
}

export default AddFundForm;
