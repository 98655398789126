import React, { useEffect } from "react";
import DefaultView from "../components/DefaultView";
import {
  fetchPaymentHistory,
  fetchMonthlyTotals,
} from "../store/actions/paymentAction";
import { connect } from "react-redux";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

const ColumnChart = ({ chartData }) => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#74788d"],
      },
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={[{ data: chartData }]}
      type="bar"
      height={350}
    />
  );
};

const TransactionHistory = ({
  fetchPaymentHistory,
  loading,
  transactions,
  currentPage,
  totalPages,
  error,
  fetchMonthlyTotals,
  monthlyTotalLoader,
  monthlyTotals,
}) => {
  console.log({ monthlyTotals });
  // Call the action when the component mounts or page changes
  useEffect(() => {
    fetchPaymentHistory(currentPage);
  }, [currentPage, fetchPaymentHistory]);

  useEffect(() => {
    fetchMonthlyTotals();
  }, []);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      fetchPaymentHistory(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      fetchPaymentHistory(currentPage - 1);
    }
  };
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Billing History</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                <div className="card-body">
                  {loading && <p>Loading...</p>}
                  {error && <p>Error: {error}</p>}

                  {!loading && !error && (
                    <>
                      <table className="table table-hover align-middle">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Currency</th>
                            <th scope="col">Email</th>
                            <th scope="col">Card Holder Name</th>
                            <th scope="col">Country</th>
                            <th scope="col">ZIP</th>
                            <th scope="col">Payment Type</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map((transaction) => (
                            <tr key={transaction.ID}>
                              <td>{transaction.ID}</td>
                              <td>{transaction.amount}</td>
                              <td>{transaction.currency}</td>
                              <td>{transaction.email}</td>
                              <td>{transaction.cardHolderName}</td>
                              <td>{transaction.country}</td>
                              <td>{transaction.zip}</td>
                              <td>
                                {/* Map payment_type_ID to a human-readable name if needed */}
                                {transaction.payment_type_ID === 5
                                  ? "Credit Card"
                                  : "Other"}
                              </td>
                              <td>
                                <span
                                  className={`badge bg-${
                                    transaction.status === "completed"
                                      ? "success"
                                      : transaction.status === "pending"
                                      ? "warning"
                                      : transaction.status === "failed"
                                      ? "danger"
                                      : transaction.status === "refunded"
                                      ? "secondary"
                                      : "light"
                                  }`}
                                >
                                  {transaction.status}
                                </span>
                              </td>
                              <td>
                                {moment(transaction.created_at).format(
                                  "MMM D, YYYY"
                                )}
                                <br></br>
                                <span className="font-size-12">
                                  {moment(transaction.created_at).format(
                                    "hh:mm A"
                                  )}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="pagination d-flex justify-content-between align-items-center">
                        <button
                          className="btn btn-primary"
                          onClick={goToPreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>
                        <button
                          className="btn btn-primary"
                          onClick={goToNextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {/* end card */}
              </div>
              {/* end col */}
            </div>
          </div>
          {/* container-fluid */}

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Monthly Payments</h4>
                </div>
                <div className="card-body">
                  {monthlyTotalLoader && <p>Loading chart...</p>}
                  {!monthlyTotalLoader && (
                    <ColumnChart chartData={monthlyTotals} />
                  )}
                </div>
              </div>
              {/*end card*/}
            </div>
          </div>
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.payment.loading,
  transactions: state.payment.transactions,
  currentPage: state.payment.currentPage,
  totalPages: state.payment.totalPages,
  error: state.payment.error,
  monthlyTotalLoader: state.payment.monthlyTotalLoader,
  monthlyTotals: state.payment.monthlyTotals,
});

const mapDispatchToProps = {
  fetchPaymentHistory,
  fetchMonthlyTotals,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
